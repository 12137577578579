<template>
  <div :class="$style.blog">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + 'blog'" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
      >
        Сохранить контент
      </el-button>
    </div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h2 :class="$style.name">Страница всех статей блога</h2>
      <el-form-item label="SEO-тексты">
        <SeoTextsBlock :seoTexts="form.seoTexts" @getSeo="getSeoTexts" />
      </el-form-item>
      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock :seoFAQs="form.seoFaqs" @getSeo="getSeoFaqs" />
      </el-form-item>
      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'

export default {
  components: {
    SeoBlock,
    SeoTextsBlock,
    SeoFaqsBlock,
  },
  data() {
    return {
      form: {
        seoTexts: [],
        seoFaqs: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },

  created() {
    this.getContent()
  },

  methods: {
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get('blog', 'addwine')
      loading.close()

      if (error) return
      this.form = value.content
      this.form.seoTexts =
        this.form?.seoTexts?.map((seoText) => seoText.id) ?? []
      this.form.seoFaqs = this.form?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []
    },

    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))

          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'blog',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }
          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })
          this.$router.push('/addwine/content/pages')
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.blog {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;

    .button {
      margin-left: 1rem;
    }
  }
  .form {
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    .seoTexts {
      width: 100%;
    }
  }
}
</style>
